.box-deposit {
    margin-top: 20px;
    .head {
        font-size: 14px;
        padding: 10px 15px;
        background-color: #f2f2f2;
        color: $black;
        opacity: 0.8;
        border-bottom: 1px solid #aaa;
    }
    .child {
        display: flex;
        position: relative;
        padding: 15px;
        border: 1px solid #aaa;
        font-size: 14px;
        margin: 6px 0;
        cursor: pointer;
        border-radius: 4px;
        .left {
            // display: inline-flex;
            // justify-content: flex-start;
            display: block;
            width: 70%;
            .logo {
                float: left;
                padding: 4px 10px 4px 0;

                img {
                    width: 60px;
                }
            }
            .product-name {
                font-size: 14px;
                font-weight: 600;
            }
            .product-detail {
                font-size: 12px;
                color: $productDetail;
                opacity: 0.8;
            }
        }
        .right {
            display: inline-flex;
            justify-content: flex-end;
            width: 40%;

            .status {
                border-radius: 2rem;
                position: absolute;
                top: 50%;
                transform: translate(0%, -50%);
                padding: 2px 10px;
                &.active {
                    color: $white;
                    background-color: #28a745;
                }
                &.inactive {
                    color: $white;
                    background-color: #dc3545;
                }
            }

            .product-price {
                position: absolute;
                top: 50%;
                transform: translate(0%, -50%);

                .price-disc {
                    font-size: 12px;
                    text-align: center;
                }
                .price-normal {
                    font-size: 10px;
                }
            }

            .icon {
                margin-left: 10px;
                font-size: 16px;
                opacity: 0.5;
                color: $black;
            }
        }
        &.disabled {
            pointer-events: none;
            cursor: not-allowed;
            background-color: $border;
        }
    }
}
