.menuBar {
    margin-top: 10px;
    // box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
    .menu {
        height: 40px;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        justify-content: center;
        background-color: #fff;
        .menu-item {
            display: flex;
            flex-basis: 28%;
            justify-content: center;
            padding: 0 12px;
            margin: 0 12px;
            cursor: pointer;
            opacity: 0.6;
            // fill: red;
            // color: red;
            // max-width: 168px;
            // min-width: 80px;

            &.active {
                color: $primary;
                opacity: 1;
                border-bottom: 2px solid $primary;
            }
            .title {
                line-height: 2.5;
                font-size: 16px;
                // color: $black;
            }
        }
    }
}
