.card-akun {
    position: relative;
    display: flex;
    flex-direction: column;
    // min-width: 0;
    width: 100%;
    max-width: 500px;
    word-wrap: break-word;
    background-color: $white;
    background-clip: border-box;
    // border: 1px solid #eaedf2;
    // border-radius: 0.3125rem;
    // padding: 0 15px;
    overflow: auto;
    margin-bottom: 56px;
    z-index: $zIndex1000;

    .box {
        .head {
            font-size: 14px;
            padding: 10px 15px;
            background-color: #f2f2f2;
            color: $black;
            opacity: 0.8;
            border-bottom: 1px solid #aaa;
        }
        .child {
            display: flex;
            position: relative;
            padding: 10px 15px;
            border-bottom: 1px solid #aaa;
            font-size: 14px;
            cursor: pointer;
            .left {
                display: inline-flex;
                justify-content: flex-start;
                width: 50%;
            }
            .right {
                display: inline-flex;
                justify-content: flex-end;
                width: 50%;

                .icon {
                    margin-left: 10px;
                    font-size: 16px;
                    opacity: 0.5;
                    color: $black;
                }
                &.danger {
                    color: #dc3545;
                }
                &.success {
                    color: #28a745;
                }
                &.warning {
                    color: #ffc107;
                }
            }
        }
    }
}
