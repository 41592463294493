body {
    .app {
        max-width: $panjangBody;
        background-color: $white;
        min-height: 100vh;
        padding: 60px 15px;
        margin: auto;
    }
}

.primary-color {
    color: $primary !important;
}

.section-body {
    display: flex;
    background-color: #e8e8e8 !important;
    // position: relative;
    width: 100%;
    // height: 100%;
    height: 100vh;
    margin-right: auto;
    margin-left: auto;
    padding-top: 56px;

    &.overflow {
        height: 100% !important;
    }

    &.refact {
        padding-top: 0 !important;

        .card {
            background-color: $white !important;
        }
    }

    .card {
        position: relative;
        display: flex;
        flex-direction: column;
        // min-width: 0;
        width: 100%;
        max-width: 500px;
        word-wrap: break-word;
        background-color: #f7f7f7;
        background-clip: border-box;
        // border: 1px solid #eaedf2;
        // border-radius: 0.3125rem;
        padding: 0 15px;
        // overflow: auto;
        // margin-bottom: 50px;
        z-index: $zIndex1000;

        .box {
            margin-top: 15px;
            background-color: $white;
            padding: 10px;

            .balance {
                display: flex;
                justify-content: center;
                .child {
                    display: block;
                    width: 100%;
                    font-size: 18px;
                    font-weight: 300;
                    margin-left: 10px;
                    margin-right: 10px;
                    text-align: center;
                    .icon {
                        color: $primary;
                    }
                    .cat {
                        font-size: 16px;
                        line-height: 26px;
                        font-weight: normal;
                    }
                    .isi {
                        // display: flex;
                        font-size: 14px;
                        font-weight: 500;
                        color: $primary;
                    }
                }
                .saldo {
                    display: block;
                    // width: 60%;
                    font-size: 18px;
                    font-weight: 300;
                    margin-left: 20px;
                    .isi {
                        display: flex;
                        font-size: 14px;
                        font-weight: 500;
                        color: $primary;
                    }
                }
                .point {
                    display: block;
                    // width: 50%;
                    font-size: 18px;
                    font-weight: 300;
                    margin-left: 20px;
                    .isi {
                        display: flex;
                        font-size: 14px;
                        font-weight: 500;
                        color: red;
                    }
                }
                .deposit {
                    display: flex;
                    justify-content: center;
                    width: 15%;
                    position: relative;
                    cursor: pointer;
                    .icon {
                        position: absolute;
                        top: -3px;
                        padding: 4px;
                        background-color: $primary;
                        border-radius: 5px;
                        color: $white;
                    }
                    .title {
                        position: absolute;
                        bottom: -3px;
                        font-size: 12px;
                    }
                }
                .tarik {
                    display: flex;
                    justify-content: center;
                    width: 15%;
                    position: relative;
                    cursor: pointer;
                    .icon {
                        position: absolute;
                        top: -3px;
                        padding: 4px;
                        background-color: $primary;
                        border-radius: 5px;
                        color: $white;
                    }
                    .title {
                        position: absolute;
                        bottom: -3px;
                        font-size: 12px;
                    }
                }
            }

            .balance-action {
                display: flex;
                justify-content: center;
                .child {
                    display: block;
                    width: 100%;
                    font-size: 18px;
                    font-weight: 300;
                    margin-top: 20px;
                    margin-bottom: 10px;
                    margin-left: 10px;
                    margin-right: 10px;
                    text-align: center;
                    .cat {
                        background-color: $primary;
                        color: $white;
                        padding: 8px;
                        border-radius: 5px;
                        font-size: 14px;
                        line-height: 26px;
                        font-weight: normal;
                    }
                    .isi {
                        // display: flex;
                        font-size: 14px;
                        font-weight: 500;
                        color: $primary;
                    }
                }
            }

            label {
                display: -ms-flexbox;
                display: flex;
                -ms-flex-align: center;
                align-items: center;

                span {
                    font-size: 13px;
                    font-weight: 400;
                    // color: #888;
                }
            }

            .cti {
                margin-bottom: 16px;

                .group {
                    color: rgba(0, 0, 0, 0.7);
                    border-bottom-width: 1px;
                    border-bottom-color: #e5e7e9;
                    border-bottom-style: solid;
                    display: -webkit-box;
                    display: -webkit-flex;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-align-items: center;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    position: relative;
                    -webkit-transition: border-bottom-style 280ms ease;
                    transition: border-bottom-style 280ms ease;

                    .form-control {
                        width: 100%;
                        height: 33px;
                        color: rgba(49, 53, 59, 0.96);
                        font-size: 1.1428571428571428rem;
                        font-weight: 400;
                        line-height: 22px;
                        background-color: transparent;
                        border: none;
                        border-radius: 0;
                        display: -webkit-box;
                        display: -webkit-flex;
                        display: -ms-flexbox;
                        display: flex;
                        -webkit-flex: 1;
                        -ms-flex: 1;
                        flex: 1;
                        position: relative;
                        outline: none;
                        padding: 5px;
                        margin: 0;
                        -webkit-transition: color 280ms ease;
                        transition: color 280ms ease;

                        &:focus {
                            border-bottom: 1px solid #00838d;
                        }
                        &.err {
                            border-bottom: 1px solid rgb(239, 20, 74);
                        }
                        &:disabled {
                            &:focus {
                                border-bottom: none;
                            }
                            background-color: #e9ecef;
                            border-radius: 4px;
                            cursor: not-allowed;
                        }
                        &:read-only {
                            &:focus {
                                border-bottom: none;
                            }
                            background-color: #e9ecef;
                            border-radius: 4px;
                            cursor: not-allowed;
                        }
                    }

                    .close {
                        background-color: #c1c1c1;
                        border-radius: 100%;
                        padding: 4px 8px;
                        cursor: pointer;
                        margin-left: 6px;
                        border: none;
                        outline: none;
                    }
                }
                .info-err {
                    width: 100%;
                    height: 22px;
                    min-height: 22px;
                    display: table;
                    padding-top: 4px;

                    .err {
                        font-size: 0.857143rem;
                        line-height: 1.5;
                        display: table-cell;
                        vertical-align: top;
                        color: rgb(239, 20, 74);
                    }
                }

                .info-warning {
                    width: 100%;
                    height: 22px;
                    min-height: 22px;
                    display: table;
                    padding-top: 4px;

                    .warning {
                        font-size: 0.857143rem;
                        line-height: 1.5;
                        display: table-cell;
                        vertical-align: top;
                        color: #ffc107;
                    }
                }
            }

            .btn:not(:disabled):not(.disabled) {
                cursor: pointer;
            }
            .btn:disabled {
                cursor: not-allowed;
                opacity: 0.5;
            }
            .btn-block {
                display: block;
                width: 100%;
            }
            .btn-primary {
                color: $white;
                background-color: $primary;
                border-color: $primary;
                box-shadow: none;
            }
            .btn {
                margin: 20px 0;
                display: inline-block;
                font-weight: 400;
                text-align: center;
                vertical-align: middle;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                border: 1px solid transparent;
                border-top-color: transparent;
                border-right-color: transparent;
                border-bottom-color: transparent;
                border-left-color: transparent;
                padding: 0.375rem 0.75rem;
                font-size: 1rem;
                line-height: 1.5;
                border-radius: 0.25rem;
                transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
                    box-shadow 0.15s ease-in-out;
            }

            h3 {
                margin: 0;
            }
        }

        .list-produkct {
            display: -ms-flexbox;
            display: flex;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;

            .box {
                position: relative;
                border-radius: 3px;
                background-color: #f9f9f9;
                border: 1px solid #d4d4d4;
                text-align: center;
                cursor: pointer;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                margin: 0.3rem;
                -ms-flex-preferred-size: 100px;
                flex-basis: 100px;
                // max-height: 120px;
                // min-width: 140px;
                // height: 60px;
                height: auto;
                width: 100px;

                &.inactive {
                    cursor: not-allowed;
                    opacity: 0.6;
                }

                img {
                    // position: absolute;
                    // top: 0;
                    // left: 30%;
                    width: 40px;
                    height: 40px;
                }
                svg {
                    position: absolute;
                    top: 0;
                    left: 35%;
                    // width: 40px;
                    height: 40px;
                    margin: 0 !important;
                }
                p {
                    font-size: 10px;
                    font-weight: 600;
                    color: $primary;
                    margin: 0;
                    width: 100%;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    // position: absolute;
                    // bottom: 8px;
                    // left: 0;
                    // margin: 4px 4px 8px 4px;
                    // overflow: hidden;
                    // text-overflow: ellipsis;
                    // display: -webkit-box;
                    // -webkit-line-clamp: 3;
                    // -webkit-box-orient: vertical;
                }

                .habis {
                    position: absolute;
                    top: -1px;
                    right: -1px;
                    z-index: 4;
                    padding: 2px 5px;
                    height: 18px;
                    background: #666;
                    color: #fff;
                    letter-spacing: 0.8px;
                    font-size: 10px;
                    line-height: 12px;
                    border-top-right-radius: 4px;
                    border-bottom-left-radius: 4px;
                }
            }
        }
    }
}
