.bottomBar {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: $zIndex9999;
    max-width: $panjangBody;
    left: 50%;
    right: 50%;
    transform: translate(-50%, 0px);
    // box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
    background: $white;
    box-shadow: 0px -2px 10px 5px rgba(196, 196, 196, 0.25);
    height: 70px;
    .menu {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        // margin: 14px 35px;
        height: 70px;

        .menu-item {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 0px;
            width: 100%;
            // height: 44px;
            height: 70px;
            // margin: 0px 40px;
            cursor: pointer;

            &.active {
                color: $hijau;
                .icon,
                .title {
                    color: $hijau;
                }

                .title::before {
                    content: "";
                    width: 4px;
                    height: 4px;
                    background: $hijau;
                    border-radius: 100%;
                }
            }

            .icon {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 15px;
                height: 15px;
                color: $primaryFont;
            }
            .title {
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;
                font-family: Raleway;
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 14px;
                /* identical to box height */

                text-align: center;

                /* Bold Font */

                color: $boldFont;
                margin-top: 4px;

                &::before {
                    content: "";
                    width: 4px;
                    height: 4px;
                }
            }
        }
    }
}
