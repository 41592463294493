%raleway-font {
    font-family: Raleway;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
}
%poppins-font {
    font-family: Poppins;
    font-style: normal;
}
%opensans-font {
    font-family: Open Sans;
    font-style: normal;
}

$black: #000;
$white: #fff;
$primary: #2e186a;
$secondary: #0a1f44;
$border: #aaaaaa;
$productDetail: #666;
$zIndex9999: 9999;
$zIndex1000: 1000;
$zIndex1200: 1200;
$zIndex0: 0;
$zIndex1: 1;
$zIndex10: 10;
$zIndex11: 11;
$zIndexBackdrop: 10000;
$zIndexModal: 11000;

$panjangBody: 480px;
$heightHeader: 60px;

$boldFont: #666666;
$primaryFont: #999999;
$hijau: #27ae60;
$kulit: #ffc0c9;
$linkActive: #00a9e0;
$meyah: #f86c6b;
$unyu: #e83e8c;

$danger: #dc3545 !important;
$info: #17a2b8 !important;
