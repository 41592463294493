.login-or {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;

    /* Bold Font */
    color: $boldFont;
    text-align: center;
}

.login-group-btn {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    justify-content: center;
    margin: 30px 0px;

    .wtIcon {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 72px;
        margin: 10px;
        cursor: pointer;

        .icon {
            width: 100%;
            max-width: 40px;
            height: auto;
            max-height: 40px;
        }

        .icon-google {
            width: 100%;
            max-width: 40px;
            height: auto;
            max-height: 40px;
        }
        .icon-facebook {
            width: 100%;
            max-width: 40px;
            height: auto;
            max-height: 40px;
        }

        h3 {
            margin: 0;
            font-family: Open Sans;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;

            /* Bold Font */
            color: $boldFont;
        }
    }
}
