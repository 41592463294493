.profilePage {
    margin: 20px 15px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .profile {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-bottom: 40px;
        .image {
            // margin-bottom: 5px;
            width: 90px;
            height: 90px;
            img {
                width: 90px;
                height: 90px;
            }
            .ubah-foto {
                border-radius: 0 0 90px 90px;
                width: 90px;
                height: 45px;
                transform: translateY(-108%);
                display: flex;
                justify-content: center;
                align-items: center;
                color: #fff;
                background: $boldFont;
                opacity: 0.7;
                cursor: pointer;

                @extend %opensans-font;
                font-size: 12px;
                // line-height: 16px;
            }
        }
    }
    .form-section {
        display: flex;
        flex-direction: column;
        // justify-content: center;
        // align-items: center;
        width: 100%;

        .group-input {
            @extend %group-input;
        }
        // .cti {
        //     @extend %cti;
        // }

        .dropify-wrapper {
            // display: block;
            // position: relative;
            // cursor: pointer;
            // overflow: hidden;
            // width: 100%;
            // max-width: 100%;
            // height: 200px;
            // padding: 5px 10px;
            // font-family: "Roboto", "Helvetica Neue", "Helvetica", "Arial";
            // font-size: 14px;
            // line-height: 22px;
            // color: #777;
            // background-color: #fff;
            // background-image: none;
            // text-align: center;
            border: 2px dashed #e5e5e5;
            border-radius: 10px;
            // transition: border-color 0.15s linear;
        }
        .btn-submit{
            margin-top: 20px;
        }
    }
}
