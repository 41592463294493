.contact-picker {
    cursor: pointer;
    display: flex;
    position: relative;
    border: 1px solid #aaa;
    border-radius: 4px;
    font-size: 1em;
    padding: 0.75em;
    .left {
        display: inline-flex;
        justify-content: flex-start;
        width: 50%;
        .icon {
            margin-right: 10px;
            font-size: 16px;
            opacity: 0.5;
            color: $black;
        }
    }
    .right {
        display: inline-flex;
        justify-content: flex-end;
        width: 50%;
        .icon {
            margin-left: 10px;
            font-size: 16px;
            opacity: 0.5;
            color: $black;
        }
    }
}
