@-webkit-keyframes animatePulse {
    0% {
        -webkit-transform: scale(0.1, 0.1);
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(1.2, 1.2);
        opacity: 0;
    }
}

@keyframes animatePulse {
    0% {
        -webkit-transform: scale(0.1, 0.1);
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(1.2, 1.2);
        opacity: 0;
    }
}

.homePage {
    position: relative;
    // padding: 0 5px;

    .background {
        position: fixed;
        top: 0;
        max-width: $panjangBody;
        left: 50%;
        right: 50%;
        transform: translate(-50%, 0px);
        width: 100%;
        background-color: $hijau;

        z-index: $zIndex10;

        transition: 0.4s;

        &.shrink {
            top: -30px;
        }

        &::after {
            content: "";

            position: absolute;
            left: 0;
            top: 120px;
            // height: 50%;
            right: 0;

            // background: blue;
            z-index: 0;
        }
    }

    .profile-container {
        display: -ms-flexbox;
        display: flex;
        flex-direction: row;
        // -ms-flex-wrap: wrap;
        // flex-wrap: wrap;
        position: fixed;
        max-width: $panjangBody;
        left: 50%;
        right: 50%;
        transform: translate(-50%, 0px);
        width: 100%;
        top: 30px;
        z-index: $zIndex11;

        padding: 0 20px;

        .left {
            display: flex;
            // flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            width: 100%;

            .profile {
                display: flex;
                align-items: center;
                width: 100%;
                cursor: pointer;

                .foto {
                    display: flex;
                    align-items: center;
                    margin-right: 6px;
                    border: 2px solid $white;
                    border-radius: 20px;
                    width: 30px;
                    height: 30px;
                }

                .name {
                    display: flex;
                    align-items: center;
                    font-family: Raleway;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 19px;
                    /* identical to box height */

                    color: $white;

                    margin-right: 5px;
                    width: 100%;
                }
                .verified {
                    display: flex;
                    align-items: center;
                    width: 100%;
                }
            }
        }
        .right {
            display: flex;
            // flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            width: 100%;
            .bell {
                // position: absolute;
                // right: 0;

                display: flex;
                justify-content: center;
                align-items: center;

                background: #ffffff;
                border-radius: 20px;
                width: 30px;
                height: 30px;

                cursor: pointer;

                .pulse {
                    background-color: #e83e8c;
                    -webkit-border-radius: 30px;
                    border-radius: 30px;
                    height: 6px;
                    width: 6px;
                    position: absolute;
                    // left: 17px;
                    top: 6px;
                    transform: translate(-50%, 0px);
                    -webkit-animation: animatePulse 1s ease-out;
                    animation: animatePulse 1s ease-out;
                    -webkit-animation-iteration-count: infinite;
                    animation-iteration-count: infinite;
                }
            }
        }
    }
    .balance-container {
        position: relative;
        z-index: $zIndex10;
        height: 60px;
        // top: -30px;
        margin-top: 30px;
        background: $white;
        box-shadow: 0px 4px 10px 2px rgba(196, 196, 196, 0.25);
        border-radius: 10px;

        padding: 5px;

        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;

        &.shrink {
            z-index: $zIndex0;
        }

        .saldo {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            width: 50%;
            border-right: 0.5px solid $primaryFont;
            cursor: pointer;

            .icon {
                width: 20px;
                height: 20px;
                margin: 15px;
            }
            .grup {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                .title {
                    font-family: Raleway;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 14px;
                    /* identical to box height */

                    text-align: center;

                    /* Font Color */

                    color: $boldFont;

                    /* Inside auto layout */

                    flex: none;
                    order: 0;
                    flex-grow: 0;
                }
                .isi {
                    font-family: Open Sans;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 12px;
                    line-height: 16px;

                    /* Hejo */

                    color: $hijau;

                    /* Inside auto layout */

                    flex: none;
                    order: 1;
                    flex-grow: 0;
                }
            }
        }
        .point {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            width: 50%;
            border-left: 0.5px solid $primaryFont;
            cursor: pointer;

            .icon {
                width: 20px;
                height: 20px;
                margin: 15px;
            }
            .grup {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                .title {
                    font-family: Raleway;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 14px;
                    /* identical to box height */

                    text-align: center;

                    /* Font Color */

                    color: $boldFont;

                    /* Inside auto layout */

                    flex: none;
                    order: 0;
                    flex-grow: 0;
                }
                .isi {
                    font-family: Open Sans;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 12px;
                    line-height: 16px;

                    /* Hejo */

                    color: $hijau;

                    /* Inside auto layout */

                    flex: none;
                    order: 1;
                    flex-grow: 0;
                }
            }
        }
    }
    .promo-container {
        margin: 20px 0;
        .menu-title {
            display: -ms-flexbox;
            display: flex;
            flex-direction: row;
            // -ms-flex-wrap: wrap;
            // flex-wrap: wrap;
            margin-bottom: 20px;
            width: 100%;

            .title {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                width: 100%;

                font-family: Raleway;
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 21px;
                text-align: center;

                /* Font Color */

                color: $boldFont;

                .icon {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;

                    width: 20px;
                    height: 20px;
                    margin-right: 10px;
                }
            }
            .lainnya {
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                align-items: center;
                width: 100%;

                @extend %raleway-font;
                font-weight: 500;
                font-size: 14px;
                color: $hijau;
            }
        }
        .promo {
            .promo-item {
                display: flex;
                width: 100%;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            /* Slideshow */
            .slideshow {
                // margin: 0 auto;
                overflow: hidden;
                // max-width: 500px;

                display: flex;
                flex-direction: column;
                height: auto;

                // .slideshowArrows {
                // flex-direction: row;
                // justify-content: center;
                // align-items: center;
                // .left {
                //     position: absolute;
                //     top: 50%;
                //     left: 50%;
                //     transform: translate(-50%, -50%);
                // }
                // .right {
                //     position: absolute;
                //     top: 50%;
                //     right: 50%;
                //     transform: translate(-50%, -50%);
                // }
                // }
            }
            .slideshowSlider {
                white-space: nowrap;
                transition: ease 1000ms;
            }
            .slide {
                position: relative;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: auto;
                // border-radius: 40px;
                text-align: center;
                cursor: pointer;
                img {
                    // max-width: 250px;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
                .left {
                    position: absolute;
                    top: 50%;
                    left: 2%;
                    transform: translate(0, -50%);

                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    background-color: $white;
                }
                .right {
                    position: absolute;
                    top: 50%;
                    right: 2%;
                    transform: translate(0, -50%);

                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    background-color: $white;
                }
            }

            /* Buttons */
            .slideshowDots {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                height: 10px;
                margin: 20px 0;
            }
            .slideshowDot {
                display: inline-block;
                height: 7px;
                width: 7px;
                border-radius: 50%;
                cursor: pointer;
                margin: 0px 5px;
                background-color: $primaryFont;
            }
            .slideshowDot.active {
                width: 17px;
                border-radius: 10px;
                background-color: $hijau;
            }
        }
    }

    // .rekomendasi-container {
    //     margin: 20px 0 60px 0;
    //     .menu-title {
    //         display: -ms-flexbox;
    //         display: flex;
    //         -ms-flex-wrap: wrap;
    //         flex-wrap: wrap;
    //         margin-bottom: 20px;
    //         .icon {
    //             display: flex;
    //             flex-direction: row;
    //             justify-content: flex-start;

    //             width: 20px;
    //             height: 20px;
    //             margin-right: 10px;
    //         }
    //         .title {
    //             display: flex;
    //             flex-direction: row;
    //             justify-content: flex-start;

    //             font-family: Raleway;
    //             font-style: normal;
    //             font-weight: 600;
    //             font-size: 18px;
    //             line-height: 21px;
    //             text-align: center;

    //             /* Font Color */

    //             color: $boldFont;
    //         }
    //     }
    //     .rekomendasi {
    //         display: -ms-flexbox;
    //         display: flex;
    //         -ms-flex-wrap: wrap;
    //         flex-wrap: wrap;
    //         flex-direction: row;
    //         justify-content: center;
    //         align-items: center;

    //         .rekomendasi-list {
    //             display: -ms-flexbox;
    //             display: flex;
    //             -ms-flex-wrap: wrap;
    //             flex-wrap: wrap;
    //             flex-direction: row;
    //             justify-content: center;
    //             align-items: center;
    //             // flex-basis: 100px;
    //             margin: 10px;
    //             .rekomendasi-item {
    //                 position: relative;
    //                 display: flex;
    //                 flex-direction: column;
    //                 // justify-content: center;
    //                 align-items: center;
    //                 padding: 0px;

    //                 width: 70px;
    //                 height: 110px;

    //                 cursor: pointer;

    //                 .rekomendasi-image {
    //                     display: flex;
    //                     align-items: center;
    //                     justify-content: center;
    //                     width: 70px;
    //                     height: 70px;
    //                     background: $white;
    //                     box-shadow: 0px 4px 10px 2px rgba(196, 196, 196, 0.25);
    //                     border-radius: 30px;

    //                     .icon {
    //                         width: 22px;
    //                         height: 22px;
    //                     }
    //                 }
    //                 .rekomendasi-title {
    //                     height: 30px;
    //                     width: 100%;

    //                     font-family: Open Sans;
    //                     font-style: normal;
    //                     font-weight: normal;
    //                     font-size: 11px;
    //                     line-height: 15px;
    //                     /* identical to box height */

    //                     text-align: center;

    //                     /* Font Color */

    //                     color: $boldFont;

    //                     margin-top: 10px;

    //                 }
    //             }
    //         }
    //     }
    // }
}
