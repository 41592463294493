.section-detail-produk {
    display: flex;
    background-color: #e8e8e8 !important;
    position: fixed;
    width: 100%;
    height: 100%;
    // max-width: 500px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 56px;
    .card {
        position: relative;
        display: flex;
        flex-direction: column;
        // min-width: 0;
        width: 100%;
        max-width: 500px;
        word-wrap: break-word;
        background-color: $white;
        background-clip: border-box;
        // border: 1px solid #eaedf2;
        // border-radius: 0.3125rem;
        padding: 0 15px;
        overflow: auto;
        // margin-bottom: 50px;
        z-index: $zIndex1000;

        .produk {
            padding: 20px 0px;
            width: 100%;

            h3 {
                font-weight: 600;
                font-style: normal;
                font-stretch: normal;
                letter-spacing: normal;
                text-align: left;
                margin-bottom: 24px;
                color: $secondary;
            }

            label {
                display: -ms-flexbox;
                display: flex;
                -ms-flex-align: center;
                align-items: center;

                span {
                    font-size: 13px;
                    font-weight: 400;
                    color: #888;
                    margin-bottom: 10px;
                }

                .logo {
                    margin-left: 10px;
                    font-size: 1.1428571428571428rem;
                    line-height: 22px;
                    height: 32px;
                    -webkit-transition: color 280ms ease;
                    transition: color 280ms ease;

                    img {
                        width: 68px;
                        height: 33px;
                    }
                }
            }
            .cti {
                margin-bottom: 16px;

                .group {
                    color: rgba(0, 0, 0, 0.7);
                    border-bottom-width: 1px;
                    border-bottom-color: #e5e7e9;
                    border-bottom-style: solid;
                    display: -webkit-box;
                    display: -webkit-flex;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-align-items: center;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    position: relative;
                    -webkit-transition: border-bottom-style 280ms ease;
                    transition: border-bottom-style 280ms ease;

                    .form-control {
                        width: 100%;
                        height: 33px;
                        color: rgba(49, 53, 59, 0.96);
                        font-size: 1.1428571428571428rem;
                        font-weight: 400;
                        line-height: 22px;
                        background-color: transparent;
                        border: none;
                        border-radius: 0;
                        display: -webkit-box;
                        display: -webkit-flex;
                        display: -ms-flexbox;
                        display: flex;
                        -webkit-flex: 1;
                        -ms-flex: 1;
                        flex: 1;
                        position: relative;
                        outline: none;
                        padding: 5px 0;
                        margin: 0;
                        -webkit-transition: color 280ms ease;
                        transition: color 280ms ease;

                        &:focus {
                            border-bottom: 1px solid #00838d;
                        }
                        &.err {
                            border-bottom: 1px solid rgb(239, 20, 74);
                        }
                    }

                    .close {
                        background-color: #c1c1c1;
                        border-radius: 100%;
                        padding: 4px 8px;
                        cursor: pointer;
                        margin-left: 6px;
                        border: none;
                        outline: none;
                    }

                    .cek-produk {
                        font-size: 14px;
                        margin-left: 16px;
                        color: $white;
                        padding: 6px;
                        background-color: $primary;
                        border-radius: 6px;
                        cursor: pointer;
                    }
                }
                .info-err {
                    width: 100%;
                    height: 22px;
                    min-height: 22px;
                    display: table;
                    padding-top: 4px;

                    .err {
                        font-size: 0.857143rem;
                        line-height: 1.5;
                        display: table-cell;
                        vertical-align: top;
                        color: rgb(239, 20, 74);
                    }
                }
            }

            .nominalct {
                // padding: 20px;
                .operatorct {
                    display: -ms-flexbox;
                    display: flex;
                    -ms-flex-wrap: wrap;
                    flex-wrap: wrap;

                    @media (min-width: 320px) and (max-width: 767px) {
                        .box {
                            // flex-basis: 100% !important;
                            width: 113px !important;
                        }
                    }

                    .box {
                        position: relative;
                        border-radius: 3px;
                        background-color: #f9f9f9;
                        border: 1px solid #d4d4d4;
                        text-align: center;
                        cursor: pointer;
                        -webkit-box-sizing: border-box;
                        box-sizing: border-box;
                        margin: 4.8px;
                        margin: 0.3rem;
                        // -ms-flex-preferred-size: 19%;
                        // flex-basis: 15%;
                        // max-height: 128px;
                        // max-height: 8rem;
                        // min-width: 144px;
                        // min-width: 9rem;
                        padding: 10px;
                        width: 129px;

                        &.active {
                            border: 1px solid #00838d;
                        }

                        &.inactive {
                            cursor: not-allowed;
                            opacity: 0.6;
                        }

                        .nominal {
                            opacity: 0.7;
                            font-size: 14px;
                            padding: 0 5px;
                            // color: #000;
                            color: $secondary;
                            margin-bottom: 4px;
                        }
                        .price {
                            height: auto;
                            // color: #999;
                            color: $primary;
                            font-size: 12px;
                            font-weight: 600;
                            line-height: 11px;
                        }
                        .habis {
                            position: absolute;
                            top: -1px;
                            right: -1px;
                            z-index: 4;
                            padding: 2px 5px;
                            height: 18px;
                            background: #666;
                            color: #fff;
                            letter-spacing: 0.8px;
                            font-size: 10px;
                            line-height: 12px;
                            border-top-right-radius: 4px;
                            border-bottom-left-radius: 4px;
                        }
                    }
                }
            }
            .keteranganct {
                margin: 20px 20px 0 20px;
                border-radius: 3px;
                background-color: #eaffeb;
                border: 0.5px solid #42b549;
                padding: 16px;
                text-align: left;
                // margin-bottom: 24px;

                .ket {
                    font-size: 12px;
                    font-weight: 600;
                    color: #42b549;
                    margin-bottom: 8px;
                }
                .des {
                    font-size: 12px;
                    line-height: 1.42;
                    color: rgba(0, 0, 0, 0.7);
                }
            }
            .hargact {
                padding: 20px;

                .harga {
                    display: inline-block;
                    width: 100%;
                    label {
                        opacity: 0.7;
                        font-size: 14px;
                        text-align: left;
                        color: rgba(0, 0, 0, 0.54);
                        margin-bottom: 8px;
                        display: block;
                    }
                    .rp {
                        font-size: 22px;
                        font-weight: 700;
                        text-align: left;
                        color: rgba(0, 0, 0, 0.7);
                        display: inline-flex;
                        // width: 50%;
                        width: 100%;
                        margin-bottom: 20px;
                    }
                    span {
                        display: inline-flex;
                        font-weight: 700;
                        font-size: 20px;
                        color: rgba(0, 0, 0, 0.54);
                        // width: 50%;
                        width: 100%;
                        margin-bottom: 20px;
                        .disc {
                            font-size: 22px;
                            font-weight: 700;
                            text-align: left;
                            color: #ff5722;
                            margin-right: 8px;
                        }
                        .awal {
                            text-align: left;
                            font-weight: 500;
                            font-size: 14px;
                            text-decoration: line-through;
                        }
                    }
                    .harga-btn {
                        display: inline-flex;
                        // justify-content: flex-end;
                        // width: 50%;
                        width: 100%;
                        justify-content: center;
                        .btn-beli {
                            cursor: pointer;
                            font-size: 1rem;
                            width: 234px;
                            height: 48px;
                            line-height: 18px;
                            border-radius: 8px;
                            font-weight: 600;
                            outline: currentcolor none medium;
                            overflow: hidden;
                            padding: 0px 16px;
                            position: relative;
                            text-overflow: ellipsis;
                            transition: background 0.8s ease 0s;
                            white-space: nowrap;
                            display: inline-block;
                            background: $primary;
                            border: medium none;
                            text-indent: initial;

                            &:disabled {
                                cursor: not-allowed;
                                background: #b9c7d5;
                                span {
                                    color: $black;
                                    opacity: 0.5;
                                }
                            }

                            span {
                                opacity: 1;
                                position: relative;
                                bottom: 0px;
                                transition: opacity 0.3s linear 0s, bottom 0.3s linear 0s;
                                justify-content: center;
                                color: $white;
                                line-height: 46px;
                            }
                        }
                    }
                    // @media (min-width: 320px) and (max-width: 767px) {
                    //     .rp {
                    //         width: 100%;
                    //         margin-bottom: 20px;
                    //     }
                    //     span {
                    //         width: 100%;
                    //         margin-bottom: 20px;
                    //     }
                    //     .harga-btn {
                    //         width: 100%;
                    //         justify-content: center;
                    //         .btn-beli {
                    //             span {
                    //                 line-height: 46px;
                    //             }
                    //         }
                    //     }
                    // }
                }
            }

            .box {
                margin-top: 20px;
                .head {
                    font-size: 14px;
                    padding: 10px 15px;
                    background-color: #f2f2f2;
                    color: $black;
                    opacity: 0.8;
                    border-bottom: 1px solid #aaa;
                }
                .child {
                    display: flex;
                    position: relative;
                    padding: 15px;
                    border: 1px solid #aaa;
                    font-size: 14px;
                    margin: 4px 0 0 4px;
                    cursor: pointer;
                    border-radius: 4px;
                    .left {
                        // display: inline-flex;
                        // justify-content: flex-start;
                        display: block;
                        width: 50%;
                        .product-name {
                            font-weight: 600;
                        }
                        .product-detail {
                            color: $productDetail;
                        }
                        .product-syarat {
                            color: $productDetail;
                        }
                        .product-zona {
                            color: $productDetail;
                        }
                    }
                    .right {
                        display: inline-flex;
                        justify-content: flex-end;
                        width: 50%;

                        .product-price {
                            position: absolute;
                            top: 50%;
                            transform: translate(0%, -50%);

                            .price-disc {
                                font-size: 12px;
                                text-decoration: line-through;
                                color: $productDetail;
                            }
                            .price-normal {
                                font-size: 14px;
                                font-weight: 600;
                                color: $primary;
                            }
                        }

                        .icon {
                            margin-left: 10px;
                            font-size: 16px;
                            opacity: 0.5;
                            color: $black;
                        }
                        &.danger {
                            color: #dc3545;
                        }
                        &.success {
                            color: #28a745;
                        }
                        &.warning {
                            color: #ffc107;
                        }
                    }
                }
            }
            .box-pelanggan {
                margin-top: 20px;
                .pelanggan-item {
                    display: flex;
                    flex-wrap: wrap;
                    position: relative;
                    width: 100%;
                    margin: 4px 0;

                    p {
                        margin: 0;
                        font-size: 14px;
                    }

                    .pelanggan-left {
                        font-weight: 600;
                        width: 45%;
                        color: #707070;
                    }
                    .pelanggan-right {
                        font-weight: 600;
                        width: 55%;
                        text-align: right;
                        color: $primary;
                    }
                }
            }
        }
    }
}
