html,
body {
  overflow-x: hidden;
}
body {
  margin: 0;
  padding: 0 !important;
  background-color: #e8e8e8 !important;
}

/* @media screen and (min-width: 480px) {
  .body {
    max-width: 480px;
    margin: auto;
  }
} */




