.header {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: $white;
    z-index: $zIndex9999;
    max-width: $panjangBody;
    left: 50%;
    right: 50%;
    transform: translate(-50%, 0px);
    height: $heightHeader;

    .row-flex {
        display: flex;
        flex-flow: row wrap;
        height: $heightHeader;

        .back-left-center-right-container {
            display: flex;
            flex-direction: row;
            margin: 0 30px;
            width: 100%;
            align-items: center;
            height: $heightHeader;

            .left {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                width: 100%;
                height: $heightHeader;
                .icon {
                    cursor: pointer;
                }
                .text {
                    @extend %raleway-font;
                }
            }

            .center {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: $heightHeader;
                .icon {
                    cursor: pointer;
                }
                .text {
                    @extend %raleway-font;
                }
            }

            .right {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                width: 100%;
                height: $heightHeader;

                .text {
                    @extend %raleway-font;
                }

                .group {
                    display: flex;

                    .icon {
                        position: static;
                        width: 14px;
                        height: 16px;

                        flex: none;
                        order: 0;
                        flex-grow: 0;
                        margin: 0px 5px;
                    }

                    .text {
                        @extend %raleway-font;
                        color: $hijau !important;

                        flex: none;
                        order: 1;
                        flex-grow: 0;
                    }
                }
            }
        }
    }
}
