%group-input {
    /* Putih */
    background: $white;
    box-shadow: 0px 4px 10px 2px rgba(196, 196, 196, 0.25);
    border-radius: 10px;
    margin-bottom: 20px;
    padding: 15px 20px;

    label {
        span {
            font-family: Open Sans;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 16px;
            color: $boldFont;
        }
    }

    .form-control {
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        color: $hijau;

        width: 95%;
        background-color: transparent;
        border: none;
        border-radius: 0;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
        position: relative;
        outline: none;
        padding: 5px 0;
        margin: 0;
        -webkit-transition: color 280ms ease;
        transition: color 280ms ease;

        &:focus {
            border-bottom: 1px solid $hijau;
        }
        &.err {
            border-bottom: 1px solid $unyu;
        }
    }

    // .close {
    //     background-color: #c1c1c1;
    //     border-radius: 100%;
    //     padding: 4px 8px;
    //     cursor: pointer;
    //     margin-left: 6px;
    //     border: none;
    //     outline: none;
    // }

    .icon {
        position: absolute;
        right: 2%;
        transform: translate(-100%, -150%);
        color: $hijau;

        &:hover {
            cursor: pointer;
        }
    }

    .info-input {
        width: 100%;
        height: 22px;
        min-height: 22px;
        display: table;
        padding-top: 4px;

        .err {
            font-family: Open Sans;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 16px;
            color: $unyu;
        }
    }
}

%cti {
    margin-bottom: 16px;

    .group {
        color: rgba(0, 0, 0, 0.7);
        border-bottom-width: 1px;
        border-bottom-color: #e5e7e9;
        border-bottom-style: solid;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        position: relative;
        -webkit-transition: border-bottom-style 280ms ease;
        transition: border-bottom-style 280ms ease;

        .form-control {
            width: 100%;
            height: 33px;
            color: rgba(49, 53, 59, 0.96);
            font-size: 1.1428571428571428rem;
            font-weight: 400;
            line-height: 22px;
            background-color: transparent;
            border: none;
            border-radius: 0;
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-flex: 1;
            -ms-flex: 1;
            flex: 1;
            position: relative;
            outline: none;
            padding: 5px 0;
            margin: 0;
            -webkit-transition: color 280ms ease;
            transition: color 280ms ease;

            &:focus {
                border-bottom: 1px solid #00838d;
            }
            &.err {
                border-bottom: 1px solid rgb(239, 20, 74);
            }
        }

        .close {
            background-color: #c1c1c1;
            border-radius: 100%;
            padding: 4px 8px;
            cursor: pointer;
            margin-left: 6px;
            border: none;
            outline: none;
        }

        .icon {
            position: absolute;
            right: 1%;
            transform: translate(-50%);
            color: $border;

            &:hover {
                color: $primary;
                cursor: pointer;
            }
        }
    }
    .info-err {
        width: 100%;
        height: 22px;
        min-height: 22px;
        display: table;
        padding-top: 4px;

        .err {
            font-size: 0.857143rem;
            line-height: 1.5;
            display: table-cell;
            vertical-align: top;
            color: rgb(239, 20, 74);
        }
    }
}
