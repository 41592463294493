.saldo-point-container {
    margin: 20px 0 60px 0;
    .box-section {
        margin: 20px 0;
        display: flex;
        flex-direction: column;
        width: 100%;

        .head {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;

            .icon {
                width: 16px;
                height: 20px;
                margin-right: 10px;
            }
            .title {
                @extend %raleway-font;
                color: $boldFont;
            }
        }
        .child {
            display: flex;
            flex-direction: row;
            align-items: center;
            background: #ffffff;
            box-shadow: 0px 4px 10px 2px rgba(196, 196, 196, 0.25);
            border-radius: 10px;
            height: 60px;
            padding: 0 20px;
            margin: 10px 0;

            .left {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                width: 100%;
                height: 60px;

                .icon {
                    width: 15px;
                    height: 15px;
                    margin-right: 10px;
                }
                .text {
                    @extend %opensans-font;
                    font-weight: 600;
                    font-size: 11px;
                    color: $boldFont;
                }
            }
            .right {
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                align-items: center;
                width: 100%;
                height: 60px;

                .text {
                    display: flex;
                    align-items: center;
                    @extend %poppins-font;
                    color: $hijau;
                    font-weight: 500;
                    font-size: 11px;

                    cursor: pointer;

                    .arrow {
                        margin-left: 10px;
                    }
                }
            }
        }
    }
    .btn-section {
        margin: 20px 0;
        display: flex;
        flex-direction: row;
        width: 100%;

        .left {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 50px;
            margin-right: 5px;
        }
        .right {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 50px;
            margin-left: 5px;
        }
    }
}
