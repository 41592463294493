/* Add Animation */
@-webkit-keyframes animateFromBottom {
    from {
        bottom: -300px;
        opacity: 0;
    }
    to {
        bottom: 0;
        opacity: 1;
    }
}

@keyframes animateFromBottom {
    from {
        bottom: -300px;
        opacity: 0;
    }
    to {
        bottom: 0;
        opacity: 1;
    }
}

@-webkit-keyframes animateLinear {
    from {
        opacity: 0;
        transition: opacity linear 0.3s;
    }
    to {
        opacity: 1;
        transition: opacity linear 0.3s;
    }
}

@keyframes animateLinear {
    from {
        opacity: 0;
        transition: opacity linear 0.3s;
    }
    to {
        opacity: 1;
        transition: opacity linear 0.3s;
    }
}

@-webkit-keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.detail-backdrop {
    position: fixed;
    z-index: $zIndex1200;
    inset: 0px;
    background-color: rgba(0, 0, 0, 0.5);
}
.detail-modal {
    display: flex;
    position: fixed;
    top: auto;
    left: 0;
    right: 0;
    bottom: 0;
    height: auto;
    max-height: 100%;
    max-width: 500px;
    margin: auto;
    padding: 40px 20px 30px;
    border-radius: 15px 15px 0px 0px;
    background-color: $white;
    z-index: $zIndex9999;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.4);

    -webkit-animation-name: animateFromBottom;
    -webkit-animation-duration: 0.4s;
    animation-name: animateFromBottom;
    animation-duration: 0.4s;

    .close {
        position: absolute;
        top: 20px;
        left: 15px;
        opacity: 0.8;
        cursor: pointer;
    }

    .info {
        position: relative;
        border-radius: 4px;
        margin-top: 20px;
        width: 100%;
        // div {
        //     display: flex;
        //     flex-wrap: wrap;

        .detail-pembayaran {
            h2 {
                font-size: 14px;
            }
            .detailtrans-item {
                display: flex;
                flex-wrap: wrap;
                position: relative;
                width: 100%;
                margin: 4px 0;

                p {
                    margin: 0;
                    font-size: 14px;
                }

                .detailtrans-left {
                    width: 45%;
                    color: #707070;
                }
                .detailtrans-right {
                    font-weight: 600;
                    width: 55%;
                    text-align: right;
                }
            }
            .info-total {
                position: relative;
                width: 100%;
                background: #fff0f0;
                padding: 10px;
                margin-top: 22px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                p {
                    color: #333;
                    font-size: 14px;
                    font-weight: 600;

                    &.ttl {
                        color: $primary !important;
                        font-size: 14px;
                        font-weight: 600;
                        text-align: right;
                    }
                }
            }
            .btn-bayar {
                display: flex;
                margin-top: 12px;
                .bayar-left {
                    width: 50%;
                    color: $white;
                    background-color: $productDetail;
                    padding: 10px;
                    text-align: center;
                    border-radius: 4px;
                    margin: 6px;
                    cursor: pointer;
                }
                .bayar-right {
                    width: 50%;
                    color: $white;
                    background-color: $primary;
                    padding: 10px;
                    text-align: center;
                    border-radius: 4px;
                    margin: 6px;
                    cursor: pointer;
                }
            }
        }
    }
}

.detail-modal-deposit {
    display: flex;
    position: fixed;
    top: auto;
    left: 0;
    right: 0;
    bottom: 0;
    height: auto;
    max-height: 100%;
    max-width: 350px;
    margin: auto;
    padding: 40px 20px 30px;
    border-radius: 15px;
    background-color: $white;
    z-index: $zIndex9999;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.4);
    transform: translate(0%, -100%);
    .close {
        position: absolute;
        top: 20px;
        left: 15px;
        opacity: 0.8;
        cursor: pointer;
    }

    .info {
        position: relative;
        border-radius: 4px;
        margin-top: 10px;
        width: 100%;
        // div {
        //     display: flex;
        //     flex-wrap: wrap;

        .detail-deposit {
            h2 {
                font-size: 14px;
            }
            .detailtrans-item {
                display: flex;
                flex-wrap: wrap;
                position: relative;
                width: 100%;
                margin: 4px 0;

                p {
                    margin: 0;
                    font-size: 14px;
                }

                .detailtrans-left {
                    width: 45%;
                    color: #707070;
                }
                .detailtrans-right {
                    font-weight: 600;
                    width: 55%;
                    text-align: right;
                }
            }
            .info-total {
                position: relative;
                width: 100%;
                background: #fff0f0;
                padding: 10px;
                margin-top: 22px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                p {
                    color: #333;
                    font-size: 14px;
                    font-weight: 600;

                    &.ttl {
                        color: $primary !important;
                        font-size: 14px;
                        font-weight: 600;
                        text-align: right;
                    }
                }
            }
            .btn-bayar {
                display: flex;
                margin-top: 12px;
                .bayar-left {
                    width: 50%;
                    color: $white;
                    background-color: $productDetail;
                    padding: 10px;
                    text-align: center;
                    border-radius: 4px;
                    margin: 6px;
                    cursor: pointer;
                }
                .bayar-right {
                    width: 50%;
                    color: $white;
                    background-color: $primary;
                    padding: 10px;
                    text-align: center;
                    border-radius: 4px;
                    margin: 6px;
                    cursor: pointer;
                }
            }
        }
    }
}

.checking-session-backdrop {
    position: fixed;
    z-index: $zIndexBackdrop;
    inset: 0px;
    background-color: rgba(0, 0, 0, 0.1);
}
.checking-session {
    position: fixed;
    bottom: 10%;
    // width: 25%;
    // max-width: 500px;
    width: 100%;
    max-width: 200px;
    left: 50%;
    right: 50%;
    transform: translate(-50%, 0px);
    text-align: center;
    background-color: rgb(142, 142, 142);
    padding: 10px;
    border-radius: 100px;
    color: $white;
}

.modal-logout {
    position: fixed;
    top: auto;
    left: 0;
    right: 0;
    bottom: 50%;
    height: auto;
    max-height: 100%;
    max-width: 300px;
    margin: auto;
    padding: 15px;
    border-radius: 15px;
    background-color: $white;
    z-index: $zIndex9999;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.4);

    -webkit-animation-name: animateLinear;
    -webkit-animation-duration: 0.4s;
    animation-name: animateLinear;
    animation-duration: 0.4s;

    .title {
        font-size: 20px;
        font-weight: 600;
        text-align: center;
        line-height: 2;
    }
    .text {
        font-size: 16px;
        opacity: 0.7;
        text-align: center;
        line-height: 2;
        margin-bottom: 10px;
    }

    .exit-group-btn {
        display: -ms-flexbox;
        display: flex;
        // -ms-flex-wrap: wrap;
        // flex-wrap: wrap;
        justify-content: center;

        .list-btn {
            position: relative;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            text-align: center;
            padding: 10px;
            margin: 4px;
        }

        .cancel {
            width: 50%;
            border: solid 1px;
            border-radius: 10px;
            cursor: pointer;
            background-color: $danger;
            color: $white;
        }
        .yes {
            width: 50%;
            border: solid 1px;
            border-radius: 10px;
            cursor: pointer;
            background-color: $info;
            color: $white;
        }
    }
}

.custom-modal-backdrop {
    position: fixed;
    z-index: $zIndexBackdrop;
    inset: 0px;
    background: $boldFont;
    opacity: 0.5;
}
.custom-modal {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: auto;
    // max-height: 100%;
    max-width: 300px;
    width: 300px;
    // height: 220px;
    margin: auto;

    border-radius: 15px;
    background-color: $white;
    z-index: $zIndexBackdrop;
    // box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.4);

    -webkit-animation-name: animateLinear;
    -webkit-animation-duration: 0.4s;
    animation-name: animateLinear;
    animation-duration: 0.4s;

    .container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 15px;

        .icon {
            // margin: 7px 0 13px;
            display: flex;
            align-items: center;
            // width: 22px;
            // height: 30px;
        }
        .title {
            font-family: Open Sans;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 19px;
            /* identical to box height */

            text-align: center;

            /* Font Color */

            color: $boldFont;
            margin: 10px;
        }
        .content {
            font-family: Open Sans;
            font-style: normal;
            font-weight: normal;
            font-size: 11px;
            line-height: 15px;
            text-align: center;

            /* Font Color */

            color: $boldFont;
            // margin-bottom: 17px;
        }
    }
    .group-btn {
        display: -ms-flexbox;
        display: flex;
        // -ms-flex-wrap: wrap;
        // flex-wrap: wrap;
        justify-content: center;
        width: 100%;

        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        /* identical to box height */

        .list-btn {
            position: relative;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            text-align: center;
            padding: 20px;
            // margin: 4px;
            border-top: 0.5px solid #c4c4c4;
            // border-left: 0.1px solid #c4c4c4;
            // border-right: 0.1px solid #c4c4c4;
            // border-bottom: 0.1px solid #c4c4c4;
            cursor: pointer;
            width: 100%;
            border-radius: 0 0 0 15px;

            // &:is(:first-child) {
            //     // border-radius: 0 0 0 15px;
            //     border-left: none; /* Prevent double borders */
            // }
            // &:is(:last-child) {
            //     border-radius: 0 0 15px;
            //     border-right: none; /* Prevent double borders */
            // }
            &:not(:last-child) {
                // border-radius: 0;
                border-right: 0.5px solid #c4c4c4; /* Prevent double borders */
            }
        }

        .unyu {
            font-family: Open Sans;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 19px;
            /* identical to box height */

            text-align: center;
            color: $unyu;
        }
        .hijau {
            font-family: Open Sans;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 19px;
            /* identical to box height */

            text-align: center;
            color: $hijau;
        }
    }
}
