.menu-container {
    margin: 20px 0 60px 0;
    .menus-title {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin-bottom: 20px;
        .icon {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;

            width: 20px;
            height: 20px;
            margin-right: 10px;
        }
        .title {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;

            font-family: Raleway;
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 21px;
            text-align: center;

            /* Font Color */

            color: $boldFont;
        }
    }
    .menu {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        flex-direction: row;
        // justify-content: center;
        align-items: center;

        &.ubah-overflow {
            overflow-x: scroll;
            flex-wrap: nowrap;
            height: auto;

            -ms-overflow-style: none; /* IE and Edge */
            scrollbar-width: none; /* Firefox */
            /* Hide scrollbar for Chrome, Safari and Opera */
            &::-webkit-scrollbar {
                display: none;
            }
        }

        .menu-list {
            display: -ms-flexbox;
            display: flex;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            // flex-basis: 100px;
            margin: 10px;
            .menu-item {
                position: relative;
                display: flex;
                flex-direction: column;
                // justify-content: center;
                align-items: center;
                padding: 0px;

                width: 70px;
                height: 110px;

                cursor: pointer;

                &.ubah {
                    cursor: default;
                }

                .menu-image {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 70px;
                    height: 70px;
                    background: $white;
                    box-shadow: 0px 4px 10px 2px rgba(196, 196, 196, 0.25);
                    border-radius: 30px;

                    .icon {
                        width: 22px;
                        height: 22px;
                    }

                    .ubah {
                        position: absolute;
                        top: 0;
                        right: 0;
                        width: 22px;
                        height: 22px;
                        cursor: pointer;
                    }
                }
                .menu-title {
                    height: 30px;
                    width: 100%;

                    font-family: Open Sans;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 11px;
                    line-height: 15px;
                    /* identical to box height */

                    text-align: center;

                    /* Font Color */

                    color: $boldFont;

                    margin-top: 10px;
                }
            }
        }
    }
}
