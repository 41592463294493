.settingsPage {
    margin: 20px 15px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .profile-section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        width: 165px;

        margin-bottom: 20px;

        .profile {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            margin-bottom: 10px;
            .image {
                margin-bottom: 5px;
                img {
                    width: 90px;
                    height: 90px;
                }
            }
            .name {
                @extend %poppins-font;
                font-weight: 600;
                font-size: 18px;
                line-height: 27px;
                margin-bottom: 5px;
                color: $boldFont;
            }
            .status {
                display: flex;
                flex-direction: row;
                align-items: center;
                width: 100%;
                color: $primaryFont;
                .phone {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    width: 100%;
                    @extend %poppins-font;
                    font-weight: 500;
                    font-size: 11px;
                }
                .keanggotaan {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    width: 100%;
                    @extend %poppins-font;
                    font-weight: 500;
                    font-size: 11px;

                    .verified {
                        margin-left: 3px;
                    }
                }
            }
        }

        .profile-button {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 160px;
            height: 40px;
            color: $white;
            background: $hijau;
            box-shadow: 0px 4px 10px 2px rgba(196, 196, 196, 0.25);
            border-radius: 20px;

            border: none;

            cursor: pointer;
            .arrow {
                margin-left: 5px;
            }
        }
    }

    .box-section {
        margin: 20px 0;
        display: flex;
        flex-direction: column;
        width: 100%;

        .head {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;

            .icon {
                width: 16px;
                height: 20px;
                margin-right: 10px;
            }
            .title {
                @extend %raleway-font;
                color: $boldFont;
            }
        }
        .child {
            display: flex;
            flex-direction: row;
            align-items: center;
            background: #ffffff;
            box-shadow: 0px 4px 10px 2px rgba(196, 196, 196, 0.25);
            border-radius: 10px;
            height: 60px;
            padding: 0 20px;
            margin: 10px 0;

            .left {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                width: 100%;
                height: 60px;

                .icon {
                    width: 15px;
                    height: 15px;
                    margin-right: 10px;
                }
                .text {
                    @extend %opensans-font;
                    font-weight: 600;
                    font-size: 11px;
                    color: $boldFont;
                }
            }
            .right {
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                align-items: center;
                width: 100%;
                height: 60px;

                .text {
                    display: flex;
                    align-items: center;
                    @extend %poppins-font;
                    color: $hijau;
                    font-weight: 500;
                    font-size: 11px;

                    cursor: pointer;

                    .arrow {
                        margin-left: 10px;
                    }
                }
            }
        }
    }
    .versi-aplikasi {
        // display: flex;
        // justify-content: flex-start;
        width: 100%;
        margin-bottom: 40px;

        @extend %poppins-font;
        font-weight: 500;
        font-size: 11px;
        color: $border;
    }
    .keluar {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 50px;

        color: $white;
        background: $unyu;
        box-shadow: 0px 4px 10px 2px rgba(196, 196, 196, 0.25);
        border-radius: 10px;
        margin-bottom: 20px;

        border: none;

        cursor: pointer;
    }
}
