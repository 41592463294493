.login-container {
    padding: 0 15px;
    .login-tittle {
        font-family: Raleway;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 28px;
        display: flex;
        justify-content: center;

        /* Bold Font */
        color: $boldFont;

        padding-bottom: 30px;

        &.register {
            padding-top: 10px;
        }
    }
    .login-box {
        width: 100%;

        .group-input {
            @extend %group-input;
        }

        label {
            display: -ms-flexbox;
            display: flex;
            -ms-flex-align: center;
            align-items: center;
            span {
                font-size: 13px;
                font-weight: 400;
                // color: #888;
            }
        }

        .cti {
            @extend %cti;
        }

        // .cti {
        //     margin-bottom: 16px;

        //     .group {
        //         color: rgba(0, 0, 0, 0.7);
        //         border-bottom-width: 1px;
        //         border-bottom-color: #e5e7e9;
        //         border-bottom-style: solid;
        //         display: -webkit-box;
        //         display: -webkit-flex;
        //         display: -ms-flexbox;
        //         display: flex;
        //         -webkit-align-items: center;
        //         -webkit-box-align: center;
        //         -ms-flex-align: center;
        //         align-items: center;
        //         position: relative;
        //         -webkit-transition: border-bottom-style 280ms ease;
        //         transition: border-bottom-style 280ms ease;

        //         .form-control {
        //             width: 100%;
        //             height: 33px;
        //             color: rgba(49, 53, 59, 0.96);
        //             font-size: 1.1428571428571428rem;
        //             font-weight: 400;
        //             line-height: 22px;
        //             background-color: transparent;
        //             border: none;
        //             border-radius: 0;
        //             display: -webkit-box;
        //             display: -webkit-flex;
        //             display: -ms-flexbox;
        //             display: flex;
        //             -webkit-flex: 1;
        //             -ms-flex: 1;
        //             flex: 1;
        //             position: relative;
        //             outline: none;
        //             padding: 5px 0;
        //             margin: 0;
        //             -webkit-transition: color 280ms ease;
        //             transition: color 280ms ease;

        //             &:focus {
        //                 border-bottom: 1px solid #00838d;
        //             }
        //             &.err {
        //                 border-bottom: 1px solid rgb(239, 20, 74);
        //             }
        //         }

        //         .close {
        //             background-color: #c1c1c1;
        //             border-radius: 100%;
        //             padding: 4px 8px;
        //             cursor: pointer;
        //             margin-left: 6px;
        //             border: none;
        //             outline: none;
        //         }

        //         .icon {
        //             position: absolute;
        //             right: 1%;
        //             transform: translate(-50%);
        //             color: $border;

        //             &:hover {
        //                 color: $primary;
        //                 cursor: pointer;
        //             }
        //         }
        //     }
        //     .info-err {
        //         width: 100%;
        //         height: 22px;
        //         min-height: 22px;
        //         display: table;
        //         padding-top: 4px;

        //         .err {
        //             font-size: 0.857143rem;
        //             line-height: 1.5;
        //             display: table-cell;
        //             vertical-align: top;
        //             color: rgb(239, 20, 74);
        //         }
        //     }
        // }

        

        .alert {
            position: relative;
            padding: 0.75rem 1.25rem;
            padding-right: 1.25rem;
            margin-bottom: 1rem;
            border: 1px solid transparent;
            border-top-color: transparent;
            border-right-color: transparent;
            border-bottom-color: transparent;
            border-left-color: transparent;
            border-radius: 0.25rem;
        }
        .alert-danger {
            color: #fff;
            background-color: #d32535;
            border-color: #d32535;
        }
        .alert-success {
            color: #fff;
            background-color: #28a745;
            border-color: #28a745;
        }

        .boxLog {
            padding-top: 10px;
            margin-bottom: 30px;
            .child {
                display: flex;
                position: relative;
                // padding: 10px 0;
                font-size: 14px;
                // cursor: pointer;
                .left {
                    line-height: 34px;
                    display: inline-flex;
                    justify-content: flex-start;
                    width: 50%;

                    .remember {
                        cursor: pointer;

                        input[type="checkbox"] {
                            // Hide the browser's default checkbox
                            display: none;
                        }

                        /* Create a custom checkbox */
                        .checkmark {
                            position: relative;
                            border: 1px solid #c4c4c4;
                            box-sizing: border-box;
                            border-radius: 6px;
                            width: 20px;
                            height: 20px;
                            margin-right: 6px;

                            /* Create the checkmark/indicator (hidden when not checked) */
                            &:after {
                                content: "";
                                position: absolute;
                                display: none;
                            }
                        }

                        &:hover input ~ .checkmark {
                            border-color: $hijau;
                            // background-color: #2196f3;
                        }

                        /* Show the checkmark when checked */
                        input:checked ~ .checkmark:after {
                            display: block;
                        }

                        /* Style the checkmark/indicator */
                        .checkmark:after {
                            left: 35%;
                            top: 15%;
                            width: 6px;
                            height: 10px;
                            border: solid $hijau;
                            border-width: 0 3px 3px 0;
                            transform: rotate(45deg);
                        }

                        .text {
                            font-family: Open Sans;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 14px;
                            line-height: 19px;
                            /* identical to box height */

                            display: flex;
                            align-items: center;

                            /* Bold Font */
                            color: $boldFont;
                        }
                    }
                }
                .right {
                    line-height: 34px;
                    display: inline-flex;
                    justify-content: flex-end;
                    width: 50%;

                    .forgot {
                        font-family: Open Sans;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 19px;
                        /* identical to box height */

                        display: flex;
                        align-items: center;
                        text-align: center;

                        /* Meyah */

                        color: $meyah;
                    }
                }
            }

            & a {
                color: $primary;
            }
        }

        .boxReg {
            /* Auto layout */

            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin: 40px 0 60px 0;
            padding: 0 15px;

            .line {
                border: 1px solid $primaryFont;
                width: 50%;
                margin: 5% 0;
            }
            .daftar {
                font-family: Open Sans;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 22px;
                /* identical to box height */

                display: flex;
                align-items: center;
                text-align: center;

                /* Hejo */

                color: $hijau;
                margin: 0 28px;
            }
        }

        h3 {
            font-size: 20px;
        }

        p {
            font-size: 14px;
        }

        .persetujuan {
            font-family: Open Sans;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 16px;
            // display: flex;
            // align-items: center;

            color: $black;

            margin: 30px 0;
        }
    }
}
