@media screen and (max-width: 480px) {
    .welcome-image {
        display: flex;
        padding-bottom: 30px !important;
        width: 300px;
    }
}
.welcome-container {
    text-align: center;
    padding: 0 15px;
    .welcome-image {
        padding-bottom: 60px;
        max-width: 100%;
        height: auto;
        margin: auto;
    }
    

    .welcome-title {
        font-family: Raleway;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 28px;

        /* Bold Font */
        color: $boldFont;

        h3 {
            margin: 0;
        }
    }
    .welcome-description {
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 22px;

        /* Primary Font */
        color: $primaryFont;

        margin-top: 30px;
    }
    
    .welcome-group-btn {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        justify-content: center;
        margin: 30px 0px;
        .list-btn {
            position: relative;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            // -ms-flex-preferred-size: 100px;
            // flex-basis: 100px;
    
            margin: 10px;
        }
        .masuk {
            font-family: Open Sans;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            color: $white;
            background: $hijau;
            border-radius: 20px;
            padding: 9px 49px;
            border: none;
            cursor: pointer;
            width: 150px;
        }
        .daftar {
            font-family: Open Sans;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            background: $white;
            /* Hijau */
            color: $hijau;
            border: 1px solid $hijau;
            box-sizing: border-box;
            border-radius: 20px;
    
            padding: 9px 49px;
    
            cursor: pointer;
            width: 150px;
        }
    }
}
