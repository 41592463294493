.primary {
    color: $primary !important;
}
.secondary {
    color: $secondary !important;
}
.danger {
    color: #dc3545 !important;
}
.success {
    color: #28a745 !important;
}
.warning {
    color: #ffc107 !important;
}
