.btn:not(:disabled):not(.disabled) {
    cursor: pointer;
}
.btn-block {
    display: block;
    width: 100%;
    height: 50px;
}
.btn-primary {
    color: $white;
    background: $hijau;
    box-shadow: 0px 4px 10px 2px rgba(196, 196, 196, 0.25);
    border-radius: 10px;
}
.btn-unyu {
    color: $white;
    background: $unyu;
    box-shadow: 0px 4px 10px 2px rgba(196, 196, 196, 0.25);
    border-radius: 10px;
}
.btn {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
}

.btn-masuk {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */
    text-align: center;
    /* Putih */
    color: $white;
    height: 50px;
}
