.boxPin {
    border-bottom: 1px solid #aaa;
    margin-bottom: 15px;
    .child {
        display: flex;
        position: relative;
        padding: 10px 0;
        font-size: 14px;
        cursor: pointer;
        .left {
            line-height: 34px;
            display: inline-flex;
            justify-content: flex-start;
            width: 50%;
        }
        .right {
            line-height: 34px;
            display: inline-flex;
            justify-content: flex-end;
            width: 50%;
        }
    }
}
